import { useMemo } from "react";
import {
  pipe,
  keys,
  reduce,
  isNil,
  assoc,
  identity,
  mergeDeepRight,
  omit,
  filter,
  prop,
} from "ramda";
import qs from "query-string";
import { getUrlVars } from "shared/utils/url";

export const flattenState = (configuratorState) =>
  pipe(
    keys,
    reduce(
      (acc, k) =>
        pipe(
          ...["color", "type"].map((subK) =>
            !isNil(configuratorState[k][subK])
              ? assoc(`${k}_${subK}`, configuratorState[k][subK])
              : identity
          )
        )(acc),
      {}
    )
  )(configuratorState);

export const unflattenState = (flatState = {}) =>
  pipe(
    keys,
    reduce(
      (acc, k) =>
        assoc(
          k.split("_")[0],
          assoc(k.split("_")[1], flatState[k])(acc[k.split("_")[0]] || {})
        )(acc),
      {}
    )
  )(flatState);

export const createVentilatorStateFromBlueprint = (
  bp,
  ventilatorState,
) => {
  const minVent = bp.minVentilatorsAllowed || 0
  const maxVent = bp.maxVentilatorsAllowed || Number.MAX_SAFE_INTEGER
  return {
    ...ventilatorState,
    quantity: Math.min(Math.max(ventilatorState.quantity, minVent), maxVent),
  }
}


export const createPanelStateFromBlueprint = (
  blueprint,
  panelState,
  defaultColor,
  mergeFunction = mergeDeepRight,
) =>
  blueprint.panels.reduce(
    (acc, item) =>
      assoc(
        item.id,
        mergeFunction(
          {
            type: item.default || null,
            color: defaultColor,
          },
          panelState[item.id] || {}
        )
      )(acc),
    panelState
  );

export const createStateFromPreConfigured = (
  preConfiguredState,
  blueprints,
  defaultColor
) => currentState =>
  pipe(
    omit(["name", "id", "ventilatorType", "ventilatorQuantity"]),
    assoc("ventilator", {
      type: preConfiguredState.ventilatorType || "NONE",
      quantity: preConfiguredState.ventilatorQuantity || 1,
    }),
    assoc('hasLighting', !!preConfiguredState.hasLighting),
    assoc('blueprintRegion', currentState.blueprintRegion),
    assoc(
      "panels",
      createPanelStateFromBlueprint(
        blueprints.find((bp) => bp.id === preConfiguredState.blueprint),
        preConfiguredState.panels?.reduce(
          (acc, panel) =>
            assoc(panel.id, { type: panel.type, color: panel.color })(acc),
          {}
        ) || {},
        defaultColor
      )
    )
  )(preConfiguredState);

export const cleanPanelsFromState = (state, currentBlueprint) =>
  assoc(
    "panels",
    pipe(
      keys,
      filter((k) => currentBlueprint.panels.find((panel) => panel.id === k)),
      reduce((acc, key) => assoc(key, state.panels[key])(acc), {})
    )(state.panels)
  )(state);

export const useInitialStateFromUrl = (isRenderedOnClient) =>
  useMemo(() => {
    if (!isRenderedOnClient) return {};
    const flatConfiguratorState = getUrlVars();
    let configuratorState = flatConfiguratorState.blueprint
      ? {
          blueprint: flatConfiguratorState.blueprint,
          blueprintRegion: flatConfiguratorState.blueprintRegion,
          panels: {},
          ventilator: {
            type: flatConfiguratorState.ventilatorType,
            quantity: flatConfiguratorState.ventilatorQuantity,
          },
        }
      : {};
    configuratorState.panels = unflattenState(
      omit(
        ["blueprint", "blueprintRegion", "ventilatorType", "ventilatorQuantity"],
        flatConfiguratorState
      )
    );
    return configuratorState;
  }, [isRenderedOnClient]);

export const buildUrlQueryFromState = (configuratorState) => qs.stringify({
  blueprint: configuratorState.blueprint,
  blueprintRegion: configuratorState.blueprintRegion,
  ventilatorType: configuratorState.ventilator.type,
  ventilatorQuantity:
    configuratorState.ventilator.type !== "NONE"
      ? configuratorState.ventilator.quantity
      : null,
  ...flattenState(configuratorState.panels),
}, { skipNull: true });

export const filterVisible = list => list.filter(prop('visible'))