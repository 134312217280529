import React, { useState, useEffect, useMemo } from "react";
import { assoc, assocPath, pipe, keys, allPass, propEq, mergeDeepLeft } from "ramda";
import BoothCanvas from "./booth-canvas";
import BlueprintSelector from "./form/blueprint-selector";
import VentilatorSelector from "./form/ventilator-selector";
import RegionSelector from "./form/region-selector";
import PreConfiguredSelector from "./form/preconfigured-selector";
import { Grid, Cell } from "shared/ui/grid";
import { Note } from "shared/ui/headings";
import {
  CanvasInstruction,
  CanvasWrapper,
  Main,
  CanvasInstructionsBackdrop,
  PreviewImage,
  PreviewButtons,
  PreviewDisclaimer,
  ShortDescription,
} from "./styled";
import PanelAccordion from "./form/panel-accordion";
import {
  createPanelStateFromBlueprint,
  createStateFromPreConfigured,
  cleanPanelsFromState,
  createVentilatorStateFromBlueprint,
  filterVisible,
} from "./helpers";
import ColorSelector from "./form/color-selector";
import FloatingBuyButton from "./form/floating-buy-button";
import { Gap } from "../../../shared/ui/gap";
import { download, generatePDF } from "../../../shared/utils/pdf-generator";
import { formatState, generateConfigUrl } from "../../../shared/utils/pdf-generator/helpers";
import { useBreakpoints } from "../../../shared/hooks/use-breakpoints";
import { SmallP, P } from "../../../shared/ui/headings";
import Tabs from "shared/ui/blocks/tabs";
import { getCountryCookie, getCurrencyCookie } from "../../../shared/utils/cookies";
import { isNorthAmericaRegion, useSiteAreaContext } from "../../../shared/context/site-area";
import { useTranslations } from "../../../shared/context/translations";
import parseMarkdown from "../../../shared/utils/markdown";
import { Link } from "react-router-dom";
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from "../../../shared/ui/button";
import BlueprintLightingSelector from "./form/blueprint-selector/blueprint-lighting-selector";

const currency = getCurrencyCookie()

const Configurator = (props) => {
  const { siteArea } = useSiteAreaContext()
  const currentCountry = getCountryCookie()
  const data = props.data;
  const defaultColor = data.colors.reduce((acc, c) => (c.default ? c : acc));
  const defaultBlueprint = data.blueprints.find((bp) => bp.id === props.initialState.blueprint)
  || data.blueprints.find(bp => bp.visible);
  
  const filteredRegions = data.regions.filter(region => region.id === 'USA' || !isNorthAmericaRegion(currentCountry))
  const defaultBpRegion = filteredRegions.find(region => region.id === props.initialState.blueprintRegion)?.id
    || (filteredRegions.length === 1 && !!defaultBlueprint.regions?.length ? filteredRegions[0].id : "NONE")

  const [currentView, setCurrentView] = useState('preview');
  const [showInstructions, setShowInstructions] = useState(true);
  const [state, setState] = useState(() => ({
    blueprint: defaultBlueprint.id,
    blueprintRegion: defaultBpRegion,
    hasLighting: !!defaultBlueprint.hasLighting,
    ventilator: {
      type: props.initialState.ventilator?.type || "NONE",
      quantity:
        Math.max(
          Math.min(
            props.initialState.ventilator?.quantity || 0,
            defaultBlueprint.maxVentilatorsAllowed
          ) || 1,
          defaultBlueprint.minVentilatorsAllowed || 0,
        ),
    },
    panels: createPanelStateFromBlueprint(
      defaultBlueprint,
      props.initialState.panels || {},
      defaultColor.id
    ),
  }));

  const currentBlueprint = data.blueprints.find(
    (bp) => bp.id === state.blueprint
  );
  const selectedColor = state.panels[keys(state.panels)[0]].color;
  const visibleBlueprints = filterVisible(data.blueprints)

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    props.onChange(cleanPanelsFromState(state, currentBlueprint));
  }, [state, currentBlueprint]);

  const getPanelById = (id) => data.panels.find((panel) => panel.id === id);

  const selectBlueprint = (bp) =>
    setState(
      pipe(
        assoc("blueprint", bp.id),
        assoc(
          "panels",
          createPanelStateFromBlueprint(
            bp,
            state.panels,
            selectedColor || defaultColor.id,
            mergeDeepLeft,
          )
        ),
        assoc(
          'ventilator',
          createVentilatorStateFromBlueprint(bp, state.ventilator)
        )
      )
    );

  const selectLighting = (hasLighting) => {
    const similarBp = visibleBlueprints.find(bp => bp.name === currentBlueprint.name && !!bp.hasLighting === hasLighting)
    const newBp = similarBp || visibleBlueprints.find(bp => !!bp.hasLighting === hasLighting)
    if (!newBp) return
    setState(
      pipe(
        assoc('hasLighting', hasLighting),
        assoc("blueprint", newBp.id),
        assoc("blueprintRegion",
          newBp.regions.find(region => region.id === state.blueprintRegion)
            ? state.blueprintRegion
            : (newBp.regions[0]?.id ?? 'NONE')
        ),
        assoc(
          "panels",
          createPanelStateFromBlueprint(
            newBp,
            state.panels,
            selectedColor || defaultColor.id,
            mergeDeepLeft,
          )
        ),
        assoc(
          'ventilator',
          createVentilatorStateFromBlueprint(newBp, state.ventilator)
        )
      )
    )
  }
  const selectRegion = (region) =>
    setState(assocPath(["blueprintRegion"], region));

  const selectVentilator = (ventilator) =>
    setState(assocPath(["ventilator", "type"], ventilator));
  const selectVentilatorQuantity = (qnt) =>
    setState(assocPath(["ventilator", "quantity"], qnt));

  const selectPanel = (id) => (panel) =>
    setState(assocPath(["panels", id, "type"], panel));
  const selectColor = (id) => (color) =>
    setState(assocPath(["panels", id, "color"], color));
  const selectColorForAllPanels = (color) =>
    setState((prevState) =>
      pipe(
        ...keys(prevState.panels).map((panelId) =>
          assocPath(["panels", panelId, "color"], color)
        )
      )(prevState)
    );
  const selectPreConfigured = (config) =>
    setState(
      createStateFromPreConfigured(
        config,
        data.blueprints,
        selectedColor || defaultColor.id
      )
    );

  const formattedState = useMemo(() => formatState(data, state), [data, state]);
  const totalPrice = formattedState.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const hasNullPanels = currentBlueprint.panels.reduce(
    (acc, item) => acc || state.panels[item.id].type === null,
    false
  );
  const includedVentilators = currentBlueprint.includedVentilators || 0

  const totalVentilatorCount = includedVentilators + (state.ventilator.type === "NONE" ? 0 : state.ventilator.quantity)
  const hasDoor = currentBlueprint.panels.reduce(
    (acc, item) =>
      acc ||
      data.panels.find((p) => p.id === state.panels[item.id].type)?.type ===
        "door",
    false
  );

  const shouldSelectVentilator = currentBlueprint.minVentilatorsAllowed && state.ventilator.type === "NONE" && state.ventilator.quantity !== 0
  const shouldSelectRegion = currentBlueprint.regions?.length && state.blueprintRegion === "NONE"

  const selectedConfiguration = data.boothConfigurations.find(allPass([
    propEq('blueprint', state.blueprint),
    conf => conf.panels.every(panel => state.panels[panel.id].type === panel.type),
  ]))

  const preview = (selectedConfiguration || currentBlueprint).previews.find(
    propEq('color', selectedColor)
  )
  const regionsInBlueprint = filteredRegions.filter(region => !!currentBlueprint.regions?.find(propEq('id', region.id)))

  const isPreviewAccurate = selectedConfiguration || currentBlueprint.panels.every(panel => state.panels[panel.id].type === panel.default)
  useEffect(() => {
    if (currentView === '3d') return
    if (!isPreviewAccurate) {
      setCurrentView('3d')
    }
  }, [isPreviewAccurate])
  const t = useTranslations('configurator')

  const shopButton = <Link to={`/${siteArea}/products?filters=accessories`}><Button size={BUTTON_SIZES.TINY} variant={BUTTON_VARIANTS.INFO}>Add accessories to your VicBooth</Button></Link>
  const editUrl = useMemo(() => generateConfigUrl(state), [state])
  return (
    <Main>
      <Grid $wrap>
        <Cell
          s={isMobile ? 12 : 4}
          style={
            isMobile
              ? {}
              : {
                  minHeight: "600px",
                  height: "calc(100vh - 80px)",
                  overflow: "auto",
                }
          }
        >
          <PreConfiguredSelector
            boothConfigurations={filterVisible(data.boothConfigurations)}
            selectedConfiguration={selectedConfiguration?.id}
            selectConfiguration={selectPreConfigured}
          />
          <BlueprintLightingSelector
            blueprints={visibleBlueprints}
            hasLighting={state.hasLighting}
            selectLighting={selectLighting}
          />
          <BlueprintSelector
            blueprints={visibleBlueprints.filter(bp => !!bp.hasLighting === state.hasLighting)}
            selectedBlueprint={state.blueprint}
            selectBlueprint={selectBlueprint}
          >
            <ColorSelector
              colors={data.colors}
              selectColor={selectColorForAllPanels}
              selectedColor={selectedColor}
            />
          </BlueprintSelector>
          {!!regionsInBlueprint.length && (
            <>
              <SmallP $bold>
                {t('electricSystemSelector').replace('X', includedVentilators)}
              </SmallP>
              <Gap x={0.5} />
              <RegionSelector
                regions={regionsInBlueprint}
                selectRegion={selectRegion}
                selectedRegion={state.blueprintRegion}
              />
            </>
          )}
          {!!data.ventilators.length && (
            <>
              <SmallP $bold>{t('ventilationSelector')}</SmallP>
              <Gap x={0.5} />
              <VentilatorSelector
                ventilators={filterVisible(data.ventilators)}
                selectVentilator={selectVentilator}
                selectedVentilator={state.ventilator.type}
                quantity={state.ventilator.quantity}
                minQuantity={currentBlueprint.minVentilatorsAllowed}
                maxQuantity={currentBlueprint.maxVentilatorsAllowed}
                setQuantity={selectVentilatorQuantity}
              />
            </>
          )}
          <Gap x={0.5} />
          <SmallP $bold>{t('doorSelector')}</SmallP>
          <Gap x={0.5} />
          <PanelAccordion
            currentBlueprint={currentBlueprint}
            data={data}
            state={state}
            selectPanel={selectPanel}
            selectColor={selectColor}
          />
          <Gap x={0.5} />
          <Note>
            {t(`disclaimer.${currency}`)}
          </Note>
          <Gap x={0.5} />
        </Cell>
        <Cell s={isMobile ? 12 : 8}>
          <CanvasWrapper>
            <PreviewButtons>
              <div>
                <Tabs
                  active={currentView === 'preview' ? 0 : 1}
                  setActive={idx => setCurrentView(idx === 0 ? 'preview' : '3d')}
                >
                  <P $bold>{t('preview')}</P>
                  <P $bold>{t('interactive')}</P>
                </Tabs>
              </div>
              {currentBlueprint.shortDescription && <ShortDescription>{currentBlueprint.shortDescription}</ShortDescription>}
              {!isMobile && shopButton}
            </PreviewButtons>
            {currentView === 'preview' && preview && (
              isPreviewAccurate
                ? <PreviewImage
                  id="preview-image"
                  src={preview.preview.src}
                  alt={preview.preview.title}
                  crossOrigin="anonymous"
                />
                : <PreviewDisclaimer>
                  {t('missingPreview')}
                </PreviewDisclaimer>
            )}
            {currentView === '3d' && (
              <>
                <BoothCanvas
                  isMobile={isMobile}
                  // rotationStep={rotationStep}
                  cameraDistance={currentBlueprint.cameraDistanceFromCenter}
                  hasVentilators={totalVentilatorCount > 0}
                  ventilatorCount={totalVentilatorCount}
                  ventilatorsMatrix={currentBlueprint.ventilators.map(ventilator => ({
                    position: JSON.parse(ventilator.position),
                    rotation: JSON.parse(ventilator.rotation).map(
                      (d) => (d / 180) * Math.PI
                    ),
                  }))}
                  structureModel={currentBlueprint.model.src}
                  panels={currentBlueprint.panels.map((panel) => ({
                    matrix: {
                      position: JSON.parse(panel.position),
                      rotation: JSON.parse(panel.rotation).map(
                        (d) => (d / 180) * Math.PI
                      ),
                    },
                    model: getPanelById(state.panels[panel.id].type)?.models.find(
                      (model) => model.color === state.panels[panel.id].color
                    )?.model.src,
                  }))}
                />
                {showInstructions && (
                  <>
                    <CanvasInstructionsBackdrop
                      onClick={() => setShowInstructions(false)}
                    />
                    <CanvasInstruction onClick={() => setShowInstructions(false)}>
                      <Note>
                        <b>{t('controls')}</b>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: parseMarkdown(t('instructions')) }} />
                      </Note>
                    </CanvasInstruction>
                  </>
                )}
              </>
            )}
          </CanvasWrapper>        
        </Cell>
      </Grid>
      <FloatingBuyButton
        canProceed={!hasNullPanels && hasDoor && !shouldSelectVentilator && !shouldSelectRegion}
        missingVentilator={shouldSelectVentilator}
        missingRegion={shouldSelectRegion}
        totalPrice={totalPrice}
        onClick={() => generatePDF(
          data,
          state,
          currentView === 'preview'
            ? isPreviewAccurate
              ? 'preview'
              : 'no-image'
            : '3d',
          siteArea,
          t,
        )}
        exportScene={(scene) => {
          const OBJExporter = require('three-obj-exporter')

          const exporter = new OBJExporter()
          const data = exporter.parse(scene)
          const blob = new Blob([data], { type: "application/obj" });
          download("configuration.obj", URL.createObjectURL(blob));
        }}
        formattedState={formattedState}
        url={editUrl}
        previewUrl={isPreviewAccurate ? preview?.preview?.src : ''}
      >
        {isMobile && shopButton}
      </FloatingBuyButton>
    </Main>
  );
};

export default Configurator;
